import React, {useCallback, useEffect, useMemo, useState} from 'react';
import "./OrderView.scss";
import addIcon from "../../assets/icons/add.png";
import okIcon from "../../assets/icons/ok.png";
import image from "../../assets/S.png";

import {Link, useHistory, useParams} from "react-router-dom";
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import CartPreview from "./CartPreview/CartPreview";
import CartItems from "./CartItems/CartItems";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {authorizedGet, authorizedGetById, authorizedPatchWithId, authorizedPost} from "../Creator/CreatorFunctions";
import {Loader} from "@mantine/core";
import {creatorActions} from "../../store/slice/gateCreatorSlice";
import {cartActions} from "../../store/slice/cartSlice";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import {sortByDate} from "../../utilis/utilis";
import {visualizationActions} from "../../store/slice/visualizationSlice";
import useTranslations from "../../hooks/useTranslations";

const Cart = () => {
    const cart = useSelector(state => state.cart);
    const isOffer = cart.isOffer;
    const [showSummary, setShowSummary] = useState(false);
    const user = useSelector(state => state.user);
    const queryClient = useQueryClient();
    const content = queryClient.getQueryData("contents")
    const params = useParams();
    const dispatch = useDispatch();
    const creatorItem = useSelector(state => state.gateCreator);

    const [internalNumber, setInternalNumber] = useState("")
    const [receiver, setReceiver] = useState({
        receiverName: "",
        receiverCompany: "",
        receiverAddress: "",
        receiverZipCode: "",
        receiverCity: "",
        receiverCountry: "",
        receiverState: "",
        receiverPhone: "",
        receiverEmail: "",
    })
    const [receiverFromSelect, setReceiverFromSelect] = useState(null)

    const parsedReciever = useMemo(() => JSON.parse(receiverFromSelect), [receiverFromSelect])

    useEffect(() => {
        if (parsedReciever) {
            setReceiver({
                receiverName: parsedReciever.name + " " + parsedReciever.surname,
                receiverCompany: parsedReciever.company,
                receiverAddress: parsedReciever.street,
                receiverZipCode: parsedReciever.zipCode,
                receiverCity: parsedReciever.city,
                receiverState: parsedReciever.province,
                receiverCountry: parsedReciever.country,
                receiverPhone: parsedReciever.phone,
                receiverEmail: parsedReciever.email,
            })
        }
    }, [parsedReciever])

    const [validUntil, setValidUntil] = useState("")
    const [orderNotes, setOrderNotes] = useState("")
    const history = useHistory();
    const {data: client} = useQuery(["client", user], () => authorizedGetById("/api/clients", user.userId))
    const {
        data: clientCart,
        isLoading,
        isPlaceholderData
    } = useQuery(["cart", params.type], () => authorizedGet(`/api/${params.type}`), {
        placeholderData: [],
        refetchOnWindowFocus: false
    })
    const cartItemsAndAccessories = useMemo(() => {
        if (clientCart[0]?.items.length && clientCart[0]?.accessories.length) {
            return [...clientCart[0]?.items, ...clientCart[0]?.accessories].sort((a, b) => sortByDate(a.createdAt, b.createdAt, 'desc'))
        } else if (clientCart[0]?.items.length && !clientCart[0]?.accessories.length) {
            return [...clientCart[0]?.items].sort((a, b) => sortByDate(a.createdAt, b.createdAt, 'desc'))
        } else if (!clientCart[0]?.items.length && clientCart[0]?.accessories.length) {
            return [...clientCart[0]?.accessories].sort((a, b) => sortByDate(a.createdAt, b.createdAt, 'desc'))
        } else {
            return []
        }

    }, [clientCart])

    const [clickedItem, setClickedItem] = useState(null);
    const [priceData, setPriceData] = useState(undefined)
    const createOrderMutation = useMutation(authorizedPost, {
        onSuccess: (d) => {
            dispatch(creatorActions.updateCreator({orderInfo: d}))
            dispatch(visualizationActions.resetVisualization())
            setShowSummary(true)
            history.push("/cart/summary")
        }
    })
    const {
        summaryBoxBack,
        cartOrderError,
        cartEmptyClient,
        cartOfferConfirmButton,
        cartOrderConfirmButton,
        cartChooseOrderButton,
        cartAccessoriesDiscountProcentage,
        cartDiscountProcentage,
        cartChooseOfferButton,
        cartDiscountNet,
        cartPriceNet,
        cartVat,
        cartDiscountNetPrice,
        cartDiscountPrice, cartAddNewProject, cartBasketSummary, cartOfferTitle, cartEmptyCart,
        cartOfferDescription,
        cartMontageCost,
        cartOrderTitle,
        cartOrderDescription
    } = useTranslations();
    const cartTitle = cart?.isOffer ? cartOfferTitle : cartOrderTitle;
    const cartDescription = cart?.isOffer ? cartOfferDescription : cartOrderDescription;
    const [chosenItems, setChosenItems] = useState([])
    const isNoPrice = chosenItems.length > 0 && chosenItems.every(item =>
        item?.gate?.gateType?.name.toLowerCase().includes('przemysłowa')
    );
    const [chosenAcc, setChosenAcc] = useState([])
    const enableOrder = chosenItems.length || chosenAcc.length ? isOffer ? receiver?.receiverName?.trim().length >= 0 : true : false;
    const updateCartPrice = useMutation(authorizedPost);
    const changeAccessoryQtyMutation = useMutation(authorizedPatchWithId, {
        onSuccess: () => {
            queryClient.invalidateQueries("cart")
        }
    });

    const discountAmounts = useCallback(() => {
        const discounts = chosenItems?.length ? [...new Set(chosenItems.map(i => i.discountAmount).sort((a, b) => a - b))] : undefined
        const discountsOrders = chosenItems?.length ? [...new Set(chosenItems.map(i => i.discountPercentage).sort((a, b) => a - b))] : undefined

        if (!!discounts?.length && params.type === 'offer_carts') {
            return discounts.map((e, i) => {
                if (discounts.length - 1 === i) {
                    return `${e}%`
                } else {
                    return `${e}% / `
                }
            })
        }
        if (!!discountsOrders?.length && params.type === 'carts') {
            return discountsOrders.map((e, i) => {
                if (discountsOrders.length - 1 === i) {
                    return `${e}%`
                } else {
                    return `${e}% / `
                }
            })
        }
    }, [chosenItems])

    const accDiscountAmounts = useCallback(() => {
        const discounts = chosenAcc?.length ? [...new Set(chosenAcc.map(i => i.discountAmount).sort((a, b) => a - b))] : undefined
        const discountsOrders = chosenAcc?.length ? [...new Set(chosenAcc.map(i => i.discountPercentage).sort((a, b) => a - b))] : undefined
        if (client?.accessoriesDiscount) {
            return `${client?.accessoriesDiscount}%`
        }
        if (!!discounts?.length && params.type === 'offer_carts') {
            return discounts.map((e, i) => {
                if (discounts.length - 1 === i) {
                    return `${e}%`
                } else {
                    return `${e}% / `
                }
            })
        }
        if (!!discountsOrders?.length && params.type === 'carts') {
            return discountsOrders.map((e, i) => {
                if (discountsOrders.length - 1 === i) {
                    return `${e}%`
                } else {
                    return `${e}% / `
                }
            })
        }
    }, [chosenAcc])

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                <div className="dashboard-content__left">
                    <h2 className="content-title">{cartTitle}</h2>
                    <p className="content-description">{cartDescription}</p>
                    {isPlaceholderData && <Loader/>}
                    {!isLoading && clientCart && (clientCart[0]?.items?.length === 0 && clientCart[0]?.accessories?.length === 0 || clientCart.length === 0) &&
                        <h3 style={{textAlign: "left", margin: "50px 0px", fontSize: 32}}>{cartEmptyCart}</h3>}
                    {!isLoading && !showSummary && (clientCart[0]?.items?.length > 0 || clientCart[0]?.accessories?.length > 0) &&
                        <CartItems
                            setPriceData={setPriceData}
                            changeAccessoryQtyMutation={changeAccessoryQtyMutation}
                            showSummary={showSummary} clickedItem={clickedItem} setClickedItem={setClickedItem}
                            cart={cartItemsAndAccessories} isLoading={isLoading} client={client} isOffer={isOffer}/>}
                    {showSummary && <CartPreview
                        updateCartPrice={updateCartPrice}
                        setPriceData={setPriceData}
                        chosenAcc={chosenAcc}
                        setChosenAcc={setChosenAcc}
                        chosenItems={chosenItems}
                        setChosenItems={setChosenItems}
                        setReceiver={setReceiver}
                        receiver={receiver}
                        receiverFromSelect={receiverFromSelect}
                        setInternalNumber={setInternalNumber} setReceiverFromSelect={setReceiverFromSelect}
                        setValidUntil={setValidUntil} setOrderNotes={setOrderNotes}
                        clickedItem={clickedItem} setClickedItem={setClickedItem}
                        cart={cartItemsAndAccessories} isLoading={isLoading}/>}
                    {!isLoading && !showSummary && <Link
                        onClick={() => {
                            dispatch(creatorActions.resetCreator())
                            dispatch(summaryBoxActions.resetCurrentStep())

                            if (params.type === "carts") {
                                dispatch(creatorActions.updateCreator({
                                    isOffer: false,
                                    isOrder: true
                                }))
                                dispatch(cartActions.isOfferToggle(false))
                            } else {
                                dispatch(creatorActions.updateCreator({
                                    isOffer: true,
                                    isOrder: false
                                }))
                                dispatch(cartActions.isOfferToggle(true))
                            }
                        }}
                        className="main-button"
                        to="/creator/step/0"
                    >{cartAddNewProject} <img src={addIcon} alt="background image"/></Link>}
                </div>
                {(clientCart[0]?.items?.length > 0 || clientCart[0]?.accessories?.length > 0) &&
                    <div className="dashboard-content__right">
                        <div className="summary-container basket-container">
                            <div className="summary show">
                                <div className="summary-box">
                                    <p className="summary-box__title">{cartBasketSummary}</p>
                                    <img src={image} alt="background image"/>

                                    <div className="summary-box__item" style={{marginTop: 10}}>
                                        <p>{cartPriceNet}</p>
                                        <p style={{fontSize: 22}}>{updateCartPrice.isLoading || changeAccessoryQtyMutation.isLoading ?
                                            <Loader
                                                size={15}/> : isNoPrice ? <span style={{fontSize: 20}}>Cena na zapytnie</span> :  !chosenItems.length && !chosenAcc.length ? 0 + ' zł' : priceData ? (isOffer ? priceData?.priceData?.netRegularPrice / 100 : priceData?.priceData?.netRegularPrice / 100).toFixed(2) + " zł" : (clientCart[0]?.priceData?.netRegularPrice / 100).toFixed(2) + " zł"}</p>
                                    </div>
                                    {discountAmounts() && <div className="summary-box__item" style={{marginTop: 10}}>
                                        <p>{cartDiscountProcentage}</p>
                                        <p style={{
                                            whiteSpace: "nowrap",
                                            fontSize: 22
                                        }}>{updateCartPrice.isLoading || changeAccessoryQtyMutation.isLoading ?
                                            <Loader
                                                size={15}/> : discountAmounts()}</p>
                                    </div>}
                                    {!isOffer && chosenAcc?.length > 0 && accDiscountAmounts() &&
                                        <div className="summary-box__item" style={{marginTop: 10}}>
                                            <p>{cartAccessoriesDiscountProcentage}</p>
                                            <p style={{
                                                whiteSpace: "nowrap",
                                                fontSize: 22
                                            }}>{updateCartPrice.isLoading || changeAccessoryQtyMutation.isLoading ?
                                                <Loader
                                                    size={15}/> : accDiscountAmounts()}</p>
                                        </div>}
                                    <div className="summary-box__item" style={{marginTop: 10}}>
                                        <p>{cartDiscountNet}</p>
                                        <p style={{
                                            whiteSpace: "nowrap",
                                            fontSize: 22
                                        }}>{updateCartPrice.isLoading || changeAccessoryQtyMutation.isLoading ?
                                            <Loader
                                                size={15}/> : !chosenItems.length && !chosenAcc.length ? 0 + ' zł' : priceData ? ((priceData?.priceData?.netRegularPrice - priceData?.priceData?.netPrice) / 100).toFixed(2) + " zł" : ((clientCart[0]?.priceData?.netRegularPrice - clientCart[0]?.priceData?.netPrice) / 100).toFixed(2) + " zł"}</p>
                                    </div>
                                    {!!priceData?.priceData?.montageCost &&
                                        <div className="summary-box__item" style={{marginTop: 10}}>
                                            <p>{cartMontageCost}</p>
                                            <p style={{
                                                whiteSpace: "nowrap",
                                                fontSize: 22
                                            }}>{updateCartPrice.isLoading || changeAccessoryQtyMutation.isLoading ?
                                                <Loader
                                                    size={15}/> : priceData ? ((priceData?.priceData?.montageCost) / 100).toFixed(2) + " zł" : 0 + ' zł'}</p>
                                        </div>}
                                    <div className="summary-box__item" style={{marginTop: 10}}>
                                        <p>{cartVat}</p>
                                        <p style={{fontSize: 22}}>
                                            {[
                                                ...new Set(
                                                    [
                                                        ...(clientCart[0]?.items.map(i => i.tax) || []),
                                                        ...(clientCart[0]?.accessories.map(i => i.tax) || [])
                                                    ]
                                                )
                                            ].sort((a, b) => a - b).map(tax => `${tax}%`).join('/')}
                                        </p></div>
                                    <div className="summary-box__item" style={{marginTop: 10}}>
                                        <p style={{marginRight: 10}}>{cartDiscountNetPrice}</p>
                                        <p style={{
                                            whiteSpace: "nowrap",
                                            fontSize: 22,

                                        }}>{updateCartPrice.isLoading || changeAccessoryQtyMutation.isLoading ?
                                            <Loader
                                                size={15}/> : !chosenItems.length && !chosenAcc.length ? 0 + ' zł' :
                                                priceData ?
                                                    isNoPrice ? <span style={{fontSize: 20}}>Cena na zapytnie</span> : priceData?.priceData?.netMontagePrice ?
                                                        ((priceData?.priceData?.netMontagePrice) / 100).toFixed(2) + " zł" : ((priceData?.priceData?.netPrice - 0) / 100).toFixed(2) + " zł" :
                                                    ((clientCart[0]?.priceData?.netPrice - 0) / 100).toFixed(2) + " zł"}</p>
                                    </div>
                                    <div className="summary-box__item summary-box__last">
                                        <p>{cartDiscountPrice}</p>
                                        <p style={{fontSize: 22}}>{updateCartPrice.isLoading || changeAccessoryQtyMutation.isLoading ?
                                            <Loader
                                                size={15}/> : !chosenItems.length && !chosenAcc.length ? 0 + ' zł' : priceData ? isNoPrice ? <span style={{fontSize: 20}}>Cena na zapytnie</span> : (priceData?.priceData?.finalPrice / 100).toFixed(2) + " zł" : (client.isDistributor ? clientCart[0]?.basePriceData?.finalPrice / 100 : clientCart[0]?.priceData?.finalPrice / 100).toFixed(2) + " zł"}</p>
                                    </div>
                                    <div className="summary-box__buttons">
                                        {!showSummary && <a
                                            onClick={() => setShowSummary(true)}
                                            className="button-2"
                                            href="#">
                                            {cart.isOffer ? cartChooseOfferButton : cartChooseOrderButton}<img src={okIcon}
                                                                                                         alt="background image"/>
                                        </a>}
                                        {showSummary && <Link
                                            to={"#"}
                                            onClick={() => createOrderMutation.mutate({
                                                url: `/api/${params.type === "carts" ? "orders/create_from_cart" : "offers"}`,
                                                data: {
                                                    client: client.id,
                                                    clientNumber: internalNumber,
                                                    orderNotes,
                                                    validUntil: new Date(validUntil),
                                                    receiverName: parsedReciever?.name && parsedReciever.surname ? parsedReciever.name + " " + parsedReciever.surname : receiver.receiverName,
                                                    receiverCompany: parsedReciever?.company ? parsedReciever.company : receiver.receiverCompany,
                                                    receiverAddress: parsedReciever?.street ? parsedReciever.street : receiver.receiverAddress,
                                                    receiverZipCode: parsedReciever?.zipCode ? parsedReciever.zipCode : receiver.receiverZipCode,
                                                    receiverCity: parsedReciever?.city ? parsedReciever.city : receiver.receiverCity,
                                                    receiverState: parsedReciever?.state ? parsedReciever.state : receiver.receiverState,
                                                    receiverCountry: parsedReciever?.country ? parsedReciever.country : receiver.receiverCountry,
                                                    receiverPhone: parsedReciever?.phone ? parsedReciever.phone : receiver.receiverPhone,
                                                    receiverEmail: parsedReciever?.email ? parsedReciever.email : receiver.receiverEmail,
                                                    items: chosenItems?.map(e => e.id),
                                                    accessories: chosenAcc?.map(e => e.id)
                                                }
                                            }, {
                                                onSuccess: () => {
                                                    queryClient.removeQueries({queryKey: 'cart'});
                                                }
                                            })}
                                            className={`button-2 ${!enableOrder ? "disabled" : ""}`}
                                            href="#">
                                            {cart.isOffer ? cartOfferConfirmButton : cartOrderConfirmButton}<img
                                            src={okIcon} alt="background image"/>
                                        </Link>}
                                    </div>

                                    {showSummary && !enableOrder && <div
                                        className="summary-box__warning">
                                        {isOffer && parsedReciever?.id?.trim().length === 0 && <motion.p
                                            initial={{opacity: 0, y: 5}}
                                            animate={{opacity: 1, y: 0}}
                                            transition={{
                                                default: {
                                                    duration: .1
                                                }
                                            }
                                            }
                                        >{cartEmptyClient}</motion.p>}
                                    </div>}
                                    {createOrderMutation.isError &&
                                        <p style={{
                                            color: "red",
                                            fontWeight: 500,
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>{cartOrderError}</p>}
                                    {showSummary &&
                                        <div className="summary-box__buttons" style={{justifyContent: "center"}}>
                                            <Link
                                                to={"#"}
                                                onClick={() => setShowSummary(false)}
                                                className="button-1"
                                                href="#">
                                                {summaryBoxBack}
                                            </Link>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>

    );
};

export default Cart;
