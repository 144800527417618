import React, {useEffect, useMemo, useState} from 'react';
import "./SummaryBox.scss";
import arrowRightIcon from "../assets/icons/arrow-right.png";
import shopIcon from "../assets/icons/shop.png";
import okIcon from "../assets/icons/ok.png";
import expandIcon from "../assets/icons/fullscreen.png";
import {useDispatch, useSelector} from "react-redux";
import {Link, NavLink, useHistory, useLocation, useParams} from "react-router-dom";
import {creatorActions} from "../store/slice/gateCreatorSlice";
import SummaryDescription from "./SummaryDescription";
import {motion} from "framer-motion";
import {summaryBoxActions} from "../store/slice/summarySlice";
import SummaryBoxSteps from "./SummaryBoxSteps";
import Ktm from "./Ktm";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    authorizedGet,
    authorizedPatchWithId,
    authorizedPut,
    authorizedPutWithId
} from "../layouts/Creator/CreatorFunctions";
import {Loader} from "@mantine/core";
import SummaryBoxExtraAccessories from "./SummaryBoxExtraAccessories";
import SummaryBoxAccessories from "./SummaryBoxAccessories";
import img from "../assets/car.png";
import backgroundImage from "../assets/rama2.png";
import useTranslations from "../hooks/useTranslations";

const SummaryBox = ({handleDownloadImage, printRef}) => {
        const {
            language,
            summaryBoxSave,
            summaryBoxTitle,
            summaryBoxGateType,
            summaryBoxDrive,
            summaryBoxNetPrice,
            summaryBoxPrice,
            summaryBoxCustomPrice,
            summaryBoxWidth,
            summaryBoxHeight,
            summaryBoxDepth,
            summaryBoxLeading,
            summaryBoxLintel,
            summaryBoxRightArea,
            summaryBoxLeftArea,
            summaryBoxRoofAngle,
            summaryBoxExtraAccessories,
            summaryBoxGateAccessories,
            summaryBoxBack,
            summaryBoxNext,
            summaryBoxAddToCart,
            summaryBoxSummary
        } = useTranslations();
        const dispatch = useDispatch();
        const history = useHistory();
        const creatorItem = useSelector(state => state.gateCreator);
        const chosenHeight = useSelector(state => state.gateCreator.height);
        const chosenWidth = useSelector(state => state.gateCreator.width);
        const summaryBox = useSelector(state => state.summaryBox);

        const isEdit = useSelector(state => state.ui.isEdit);
        const chosenAccessory = useSelector(state => state?.ui?.chosenAccessory);
        const queryClient = useQueryClient()
        const user = useSelector(state => state.user);
        const [enableNextStep, setEnableNextStep] = useState(false);
        const renderImages = useSelector(state => state.visualization);
        const cart = useSelector(state => state.cart);
        const location = useLocation()
        const isMiniCreator = location.pathname.startsWith('/mini-creator');
        const isOffer = cart.isOffer;
        const checkForNextStep = () => {
            const enableCreatorDetailsNextStep = creatorItem?.width &&
                creatorItem?.height &&
                creatorItem?.depth > 0 &&
                creatorItem?.leftArea > 0 &&
                creatorItem?.rightArea > 0 &&
                creatorItem?.lintel?.value > 0 &&
                creatorItem.sizeValidation &&
                creatorItem.driveValidation &&
                (creatorItem?.driveGroups?.name.toLowerCase() === 'brak' ? true : creatorItem?.leading?.minDepth ? creatorItem?.leading?.minDepth <= creatorItem?.depth : creatorItem?.leading.id);
            const enableColorNextStep = creatorItem?.componentItems?.length > 0
            // const enableGateAccessoriesNextStep = creatorItem?.gateAccessories?.length > 0
            const enableDriveNestStep = creatorItem?.drive?.id.trim().length > 0
            const enableOrderNextStep = creatorItem?.ktmCode.includes('?')
            if (creatorItem?.driveGroups?.name.toLowerCase() === 'brak') {
                switch (summaryBox.currentStep) {
                    case 1:
                        setEnableNextStep(enableCreatorDetailsNextStep)
                        break;
                    case 2:
                        setEnableNextStep(enableColorNextStep)
                        break;
                    case 3:
                        setEnableNextStep(true)
                        break;
                }
            }
            if (!creatorItem.driveGroups) {
                switch (summaryBox.currentStep) {
                    case 1:
                        setEnableNextStep(enableCreatorDetailsNextStep)
                        break;
                    case 2:
                        setEnableNextStep(enableDriveNestStep)
                        break;
                    case 3:
                        setEnableNextStep(enableColorNextStep)
                        break;
                    case 4:
                        setEnableNextStep(!enableOrderNextStep)
                        break;
                    case 5:
                        setEnableNextStep(true)
                        break;
                }
            }
        }

        useEffect(() => {
            checkForNextStep()
        }, [summaryBox.currentStep, creatorItem])

        const {data: clientCart} = useQuery(["cart", creatorItem?.isOffer], () => authorizedGet(`/api/${creatorItem.isOffer ? "offer_carts" : "carts"}`), {
            placeholderData: [],
            enabled: !isMiniCreator,
        })

        const addGateToCartMutation = useMutation(authorizedPatchWithId, {
            retry: 3,
            onSuccess: (s) => {
                history.push(`/cart/${!isOffer ? "carts" : "offer_carts"}`)
                dispatch(creatorActions.resetCreator())
            }
        })
        const addAccessoriesToCartMutation = useMutation(authorizedPatchWithId, {
            retry: 3,
            onSuccess: (s) => {
                dispatch(creatorActions.resetCreator())
                history.push(`/cart/${!isOffer ? "carts" : "offer_carts"}`)
                queryClient.invalidateQueries('carts')
            }
        })
        const updateClientMutation = useMutation(authorizedPatchWithId, {
            onSuccess: (d) => {
                dispatch(creatorActions.resetCreator())
                history.push(`/history/offers`)
            }
        })

        const updateGateMutation = useMutation(authorizedPutWithId, {
            onSuccess: (d) => {
                dispatch(creatorActions.updateCreator(d))
            }
        })
        const saveGateMutation = useMutation(authorizedPut)


        const goToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }

        const showVisualizationHandler = () => {
            dispatch(creatorActions.showVisualization())
        }

        const nextStepHandler = () => {
            if (summaryBox.currentStep === 1) {
                updateGateMutation.mutate({
                    url: '/api/gates',
                    data: {
                        depth: creatorItem?.depth,
                        rightArea: creatorItem?.rightArea,
                        leftArea: creatorItem?.leftArea,
                        roofAngle: creatorItem?.roofAngle ? creatorItem?.roofAngle : null
                    },
                    id: creatorItem.id
                })
            }
            if (summaryBox.currentStep === 4) {
                handleDownloadImage()
            }
            dispatch(summaryBoxActions.nextCurrentStep())
        }

        const backStepHandler = () => {
            if (!creatorItem.gateType) {
                dispatch(summaryBoxActions.updateCurrentStep(0))
            } else {
                dispatch(summaryBoxActions.previousCurrentStep())
            }
        }
        const addToCartHandler = () => {
            const client = queryClient.getQueryData(["client", user])
            if (creatorItem.gateType.id) {
                addGateToCartMutation.mutate({
                    url: `/api/${creatorItem.isOffer ? "offer_carts" : "carts"}/${clientCart[0].id}/add_gate`,
                    data: {
                        gate: creatorItem.id
                    }
                })
            }
            if (creatorItem?.accessories?.length > 0) {
                creatorItem.accessories.forEach(acc => {
                    addAccessoriesToCartMutation.mutate({
                        url: `/api/${creatorItem.isOffer ? "offer_carts" : "carts"}/${clientCart[0].id}/add_accessory`,
                        data: {
                            accessory: acc.id,
                            quantity: acc.quantity
                        }
                    })
                })
            }
        }
        const isNoPrice = useMemo(() => creatorItem?.chosenGate?.isIndustrial && creatorItem?.leading?.priceType === 3 || (creatorItem?.gateType?.name?.toLowerCase().includes('przemysłowa')), [creatorItem])

        const priceFromAccessories = creatorItem?.accessories.length > 0 ? creatorItem?.accessories?.reduce((acc, curr) => acc + curr?.totalPrice, 0) / 100 : 0;
        const numbersOfPanels = [...Array(creatorItem?.height?.panelsAmount).keys()] || [];

        return (
            <>
                {creatorItem.gateType ? !creatorItem.showDescription && summaryBox.currentStep !== 0 && creatorItem.gateType &&
                    <SummaryBoxSteps enableNextStep={enableNextStep}
                                     summaryBox={summaryBox}/> : (summaryBox.currentStep !== 0 && summaryBox.currentStep !== 4) ?
                    <div style={{display: "flex", justifyContent: "center", padding: 20}}><Loader/></div> : null}
                {!creatorItem.showDescription && summaryBox.currentStep !== 0 && <motion.div
                    initial={{opacity: 0, y: 5}}
                    animate={{opacity: 1, y: 0}}
                    transition={{
                        default: {
                            duration: .4
                        }
                    }
                    }
                    className="summary-box">
                    <p className="summary-box__title">{summaryBoxTitle}</p>
                    {renderImages?.renders?.panel && <div className={'summary-box__visualization'}>
                        {<motion.div
                            initial={{opacity: 0, y: 5}}
                            animate={{opacity: 1, y: 0}}
                            className="summary-box__img"
                        >
                            {renderImages.renders.gate && (
                                <img className="visualization-img--background" src={backgroundImage}
                                     alt="" style={{
                                    zIndex: 100,
                                }}/>
                            )}
                            {renderImages.renders.panel && numbersOfPanels.map((panel, i) => (
                                <motion.img
                                    key={i}
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    className="visualization-img"
                                    src={`${process.env.REACT_APP_API_UPLOADS + renderImages.renders.panel}`}
                                    alt="" style={{
                                    zIndex: 2,
                                    height: 250 / creatorItem?.height?.panelsAmount,
                                    width: '100%'
                                }}/>
                            ))}
                            {renderImages.renders.gateAccessories && renderImages.renders.gateAccessories.map((acc, index) => {
                                const bottom = (Math.min(...acc.accPanelPos.map(el => el.name)) * (250 / chosenHeight?.panelsAmount)) - (250 / chosenHeight?.panelsAmount);
                                const left = acc.accHorizonatalPos === 0 ? '50px' : acc.accHorizonatalPos === 1 ? '50%' : '';
                                const right = acc.accHorizonatalPos === 2 ? '50px' : '';
                                const maxHeight = 250 / chosenHeight?.panelsAmount * acc.accPanelPos.length;
                                const minHeight = 25;
                                const transform = acc.accHorizonatalPos !== 1 ? 'unset' : 'translateX(-50%)';
                                const renderQuantity = acc?.renderQuantity > 0 ? acc.renderQuantity : undefined;
                                const multipleByGateWidth = acc?.multipleByGateWidth;
                                if (renderQuantity && !multipleByGateWidth) {
                                    const images = Array.from({length: renderQuantity}, (_, index) => (
                                        <img
                                            key={index}
                                            className={`visualization-img`}
                                            src={`${process.env.REACT_APP_API_UPLOADS + acc.accRender}`}
                                            alt="" style={{
                                            zIndex: 3 + index,
                                            maxHeight: maxHeight - (10 * renderQuantity) + 10,
                                            minHeight,
                                            width: acc.accName.toLowerCase().includes('wentylacyjna') ? `${((460 * 100) / chosenWidth.value)}%` : 'unset',
                                            position: acc.renderQuantity === 1 ? 'absolute' : 'unset',
                                            left: acc.renderQuantity === 1 ? left : 'unset',
                                            right: acc.renderQuantity === 1 ? right : 'unset',
                                            transform: acc.renderQuantity === 1 ? transform : 'unset'
                                        }}/>
                                    ))
                                    return (
                                        <motion.div
                                            initial={{opacity: 0, y: 5}}
                                            animate={{opacity: 1, y: 0}}
                                            style={{
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'space-around',
                                                width: '100%',
                                                height: '100%',
                                                position: 'absolute',
                                                bottom: bottom,
                                                maxHeight,
                                            }}>
                                            {images}
                                        </motion.div>
                                    )
                                } else if (multipleByGateWidth && !renderQuantity) {
                                    const numbersOfRenders = (creatorItem?.width?.value / 1000).toFixed(0);
                                    const images = Array.from({length: numbersOfRenders}, (_, index) => (
                                        <img
                                            className={`visualization-img`}
                                            src={`${process.env.REACT_APP_API_UPLOADS + acc.accRender}`}
                                            alt="" style={{
                                            zIndex: 3 + index,
                                            maxWidth: '100%',
                                            width: 100 / numbersOfRenders + '%',
                                            height: '100%',
                                        }}/>
                                    ))
                                    return (
                                        <motion.div
                                            initial={{opacity: 0, y: 5}}
                                            animate={{opacity: 1, y: 0}}
                                            style={{
                                                display: "flex",
                                                alignItems: 'center',
                                                justifyContent: 'space-around',
                                                width: '100%',
                                                height: '100%',
                                                position: 'absolute',
                                                bottom: bottom,
                                                maxHeight,
                                            }}>
                                            {images}
                                        </motion.div>
                                    )
                                } else {
                                    return (
                                        <motion.img
                                            initial={{opacity: 0, y: 5}}
                                            animate={{opacity: 1, y: 0}}
                                            className={`visualization-img ${acc.accHorizonatalPos === 1 && 'translateX'}`}
                                            src={`${process.env.REACT_APP_API_UPLOADS + acc.accRender}`}
                                            alt="" style={{
                                            zIndex: 3 + index,
                                            position: 'absolute',
                                            left,
                                            right,
                                            bottom: bottom,
                                            width: 'auto',
                                            maxHeight,
                                        }}/>
                                    )
                                }
                            })}
                        </motion.div>}
                        <img onClick={showVisualizationHandler} className="expand-icon" src={expandIcon}
                             alt="background image"/>
                    </div>}

                    {creatorItem.ktmCode && <Ktm creatorItem={creatorItem}/>}
                    {creatorItem?.gateType && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxGateType}</p>
                        <p>{language === 'pl' ? creatorItem.gateType.name : creatorItem.gateType?.translations ? creatorItem.gateType?.translations[language].name : '-'}</p>
                    </motion.div>}
                    {creatorItem?.drive && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxDrive}</p>
                        <p>{language === 'pl' ? creatorItem.drive.name : creatorItem.drive?.translations ? creatorItem.drive?.translations[language].name : '-'}</p>
                    </motion.div>}
                    {creatorItem?.width && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxWidth}</p>
                        <p className="hole-width">{creatorItem.width.value + " mm"}</p>
                    </motion.div>}
                    {creatorItem?.height && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxHeight}</p>
                        <p className="hole-width">{creatorItem.height.value + " mm"}</p>
                    </motion.div>}
                    {creatorItem?.depth > 0 && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxDepth}</p>
                        <p>{creatorItem.depth} mm</p>
                    </motion.div>}
                    {creatorItem?.rightArea > 0 && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxRightArea}</p>
                        <p>{creatorItem.rightArea + " mm"}</p>
                    </motion.div>}
                    {creatorItem?.leftArea > 0 && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxLeftArea}</p>
                        <p>{creatorItem.leftArea + " mm"} </p>
                    </motion.div>}
                    {creatorItem?.lintel && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxLintel}</p>
                        <p>{creatorItem.lintel.value} mm</p>
                    </motion.div>}
                    {creatorItem?.leading && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxLeading}</p>
                        <p>{language === 'pl' ? creatorItem.leading.name : creatorItem.leading?.translations ? creatorItem.leading?.translations[language].name : '-'}</p>
                    </motion.div>}
                    {creatorItem?.roofAngle > 0 && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxRoofAngle}</p>
                        <p>{creatorItem?.roofAngle}</p>
                    </motion.div>}
                    {creatorItem?.componentItems?.length > 0 && creatorItem?.componentItems?.map(i => {
                        return (
                            <motion.div
                                initial={{opacity: 0, y: 5}}
                                animate={{opacity: 1, y: 0}}
                                transition={{
                                    default: {
                                        duration: .1
                                    }
                                }
                                }
                                className="summary-box__item">
                                <p>{language === 'pl' ? i.component.name : i.component?.translations ? i.component?.translations[language].name : '-'}</p>
                                <p>{language === 'pl' ? i.name : i?.translations ? i?.translations[language].name : '-'}</p>
                            </motion.div>
                        )
                    })
                    }
                    {creatorItem?.gateAccessories?.length > 0 && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxGateAccessories.toUpperCase()}</p>
                    </motion.div>}
                    {creatorItem?.gateAccessories?.length > 0 && creatorItem?.gateAccessories.map(accessory => {
                        return (
                            <SummaryBoxExtraAccessories key={accessory.id} accessory={accessory} creatorItem={creatorItem}/>
                        )
                    })}
                    {creatorItem?.accessories?.length > 0 && <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .1
                            }
                        }
                        }
                        className="summary-box__item">
                        <p>{summaryBoxExtraAccessories.toUpperCase()}</p>
                    </motion.div>}
                    {creatorItem?.accessories?.length > 0 && creatorItem.accessories.map(accessory => {

                        return (
                            <SummaryBoxAccessories accessory={accessory} creatorItem={creatorItem}/>
                        )
                    })
                    }

                    <div className="summary-box__item">
                        <p>{summaryBoxNetPrice}</p>
                        {isNoPrice && <p className="item-price__small">{summaryBoxCustomPrice}</p>}
                        {!isNoPrice &&
                            <p className="item-price__small">{!creatorItem?.price && priceFromAccessories > 0 ? priceFromAccessories.toFixed(2) + " zł" : creatorItem?.price ? (creatorItem?.price / 100 + priceFromAccessories).toFixed(2) + " zł" : "-"}</p>}
                    </div>
                    <div className="summary-box__item">
                        <p>{summaryBoxPrice}</p>
                        {isNoPrice && <p className="item-price__small">{summaryBoxCustomPrice}</p>}
                        {!isNoPrice &&
                            <p className="item-price">{!creatorItem?.price && priceFromAccessories > 0 ? (priceFromAccessories * (1 + 0.23)).toFixed(2) + " zł" : creatorItem?.price ? ((creatorItem?.price / 100 + priceFromAccessories) * (1 + 0.23)).toFixed(2) + " zł" : "-"}</p>}
                    </div>
                    <div className="summary-box__buttons" style={{justifyContent: "space-between"}}>
                        <Link to={`/${isMiniCreator ? 'mini-creator' : 'creator'}/step/${summaryBox.currentStep - 1}`}
                              className="button-1"
                              onClick={backStepHandler}>{summaryBoxBack}</Link>
                        {summaryBox.currentStep !== summaryBox.numberOfSteps ?
                            <Link
                                style={!enableNextStep ? {
                                    pointerEvents: "none",
                                    filter: "grayscale(.7)"
                                } : null}
                                to={`/${isMiniCreator ? 'mini-creator' : 'creator'}/step/${summaryBox.currentStep + 1}`}
                                onClick={() => {
                                    setEnableNextStep(false)
                                    goToTop()
                                    nextStepHandler()
                                }
                                }
                                className="button-2">{summaryBoxNext} <img
                                src={arrowRightIcon} alt="background image"/>
                            </Link> :
                            !isEdit ? !isMiniCreator ?
                                    <button
                                        style={{maxWidth: "unset"}}
                                        className="button-2"
                                        onClick={() => {
                                            if (!creatorItem?.isEdit) {
                                                goToTop()
                                                addToCartHandler(creatorItem)
                                            }
                                            if (creatorItem?.isEdit) {
                                                updateClientMutation.mutate({
                                                    url: `/api/offers/${creatorItem.offerId}/update_client`,
                                                    data: {
                                                        clientNumber: creatorItem?.clientToUpdate?.clientNumber,
                                                        receiverName: creatorItem?.clientToUpdate?.receiverName,
                                                        receiverCompany: creatorItem?.clientToUpdate?.receiverCompany,
                                                        receiverAddress: creatorItem?.clientToUpdate?.receiverAddress,
                                                        receiverZipCode: creatorItem?.clientToUpdate?.receiverZipCode,
                                                        receiverCity: creatorItem?.clientToUpdate?.receiverCity,
                                                        receiverState: creatorItem?.clientToUpdate?.receiverState,
                                                        receiverCountry: creatorItem?.clientToUpdate?.receiverCountry,
                                                        receiverPhone: creatorItem?.clientToUpdate?.receiverPhone,
                                                        receiverEmail: creatorItem?.clientToUpdate?.receiverEmail,
                                                    },
                                                })
                                            }
                                        }
                                        }
                                    >
                                        {creatorItem?.isEdit ? summaryBoxSave : summaryBoxAddToCart} {!creatorItem?.isEdit && <img src={shopIcon} alt="background image"/>}
                                    </button> :
                                    <NavLink to={'/mini-creator/summary'} className="button-2">{summaryBoxSummary}</NavLink> :
                                <button
                                    style={{maxWidth: "unset"}}
                                    className="button-2"
                                    onClick={() => {
                                        if (clientCart[0]?.accessories.length === 0) {
                                            creatorItem.accessories.forEach(acc => {
                                                addAccessoriesToCartMutation.mutate({
                                                    url: `/api/${creatorItem.isOffer ? "offer_carts" : "carts"}/${clientCart[0].id}/add_accessory`,
                                                    data: {
                                                        accessory: acc.id,
                                                        quantity: acc.quantity
                                                    }
                                                })
                                            })
                                        }
                                        const acc = creatorItem?.accessories.filter(a => !clientCart[0].accessories.find(acc => acc.accessory.id === a.id));
                                        if (clientCart[0]?.accessories.length > 0 && acc.length > 0) {

                                            acc.forEach(acc => {
                                                addAccessoriesToCartMutation.mutate({
                                                    url: `/api/${creatorItem.isOffer ? "offer_carts" : "carts"}/${clientCart[0].id}/add_accessory`,
                                                    data: {
                                                        accessory: acc.id,
                                                        quantity: acc.quantity
                                                    }
                                                })
                                            })
                                        } else {
                                            history.push(`/cart/${!isOffer ? "carts" : "offer_carts"}`)
                                        }
                                    }
                                    }
                                >
                                    {summaryBoxSave} <img src={okIcon} alt="background image"/>
                                </button>}
                    </div>
                </motion.div>}
                {creatorItem.showDescription && <SummaryDescription chosenAccessory={chosenAccessory}/>}
            </>
        );
    }
;

export default SummaryBox;
